import React, { useState, useEffect } from "react";
import { Link as ScrollLink } from "react-scroll";
import { useNavigate, useLocation } from "react-router-dom";
import "../styling/navbar.css";
import Logo from "../assets/logo1.png";

const Navbar = ({ toggleModal }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  const handleNavigation = (path) => {
    if (location.pathname !== path) {
      navigate(path);
    }
    closeMenu();
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 950 && isOpen) {
        setIsOpen(false);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isOpen]);

  return (
    <nav className="navbar">
      <div className="logo-container">
        <ScrollLink
          to="acasa"
          smooth={true}
          duration={500}
          className="nav-item"
          onClick={() => handleNavigation("/")}>
          <img src={Logo} className="logo-image" alt="cautadentist.ro" />
        </ScrollLink>
      </div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <div className="centered-buttons">
          <ScrollLink
            to="acasa"
            smooth={true}
            duration={500}
            className="nav-item"
            onClick={() => handleNavigation("/")}>
            Acasă
          </ScrollLink>
          <ScrollLink
            to="despre"
            smooth={true}
            duration={500}
            className="nav-item"
            onClick={() => handleNavigation("/")}>
            Despre
          </ScrollLink>
          {/* <Link
            to="preturi"
            smooth={true}
            duration={500}
            className="nav-item"
            onClick={closeMenu}>
            Preturi
          </Link> */}
          <ScrollLink
            to="medici"
            smooth={true}
            duration={500}
            className="nav-item"
            onClick={closeMenu}>
            Medici
          </ScrollLink>
          <ScrollLink
            to="contact"
            smooth={true}
            duration={500}
            className="nav-item"
            onClick={() => handleNavigation("/")}>
            Contact
          </ScrollLink>
        </div>
        <button className="btn" onClick={toggleModal}>
          <i className="animation"></i> Înscrie-te <i className="animation"></i>
        </button>
      </div>
      <div className="hamburger" onClick={toggleMenu}>
        <div className="line1"></div>
        <div className="line2"></div>
      </div>
    </nav>
  );
};

export default Navbar;
