import React from "react";
import "../styling/privacy.css";

const PrivacyPol = () => {
  return (
    <div className="privacy-container">
      <div className="privacy-content">
        <h1>Politica de Confidențialitate</h1>
        <p>Ultima actualizare: 18 iunie 2024</p>

        <h2>1. Introducere</h2>
        <p>
          UpvisionMedia Agency respectă confidențialitatea dumneavoastră și se
          angajează să protejeze datele personale pe care le colectăm. Această
          politică de confidențialitate explică modul în care colectăm, folosim
          și protejăm informațiile personale.
        </p>

        <h2>2. Informații pe care le colectăm</h2>
        <p>
          Putem colecta informații personale, cum ar fi numele, adresa de email,
          numărul de telefon și alte detalii relevante pentru furnizarea
          serviciilor noastre.
        </p>

        <h2>3. Cum folosim informațiile</h2>
        <p>
          Folosim informațiile personale pentru a facilita conectarea
          pacienților cu medicii stomatologi, pentru a comunica cu dumneavoastră
          și pentru a îmbunătăți serviciile noastre.
        </p>

        <h2>4. Partajarea informațiilor</h2>
        <p>
          Nu vom partaja informațiile personale cu terțe părți fără
          consimțământul dumneavoastră, cu excepția cazurilor în care este
          necesar pentru a respecta obligațiile legale.
        </p>

        <h2>5. Securitatea datelor</h2>
        <p>
          Luăm măsuri de securitate adecvate pentru a proteja informațiile
          personale împotriva accesului neautorizat, modificării sau
          distrugerii.
        </p>

        <h2>6. Drepturile dumneavoastră</h2>
        <p>
          Aveți dreptul să accesați, să corectați sau să ștergeți informațiile
          personale pe care le deținem despre dumneavoastră. De asemenea, aveți
          dreptul să vă opuneți prelucrării datelor personale și să solicitați
          restricționarea acesteia.
        </p>

        <h2>7. Modificări ale politicii</h2>
        <p>
          Ne rezervăm dreptul de a modifica această politică de
          confidențialitate în orice moment. Orice modificări vor fi publicate
          pe această pagină, iar utilizarea continuă a platformei noastre va
          constitui acceptarea acestor modificări.
        </p>

        <h2>8. Contact</h2>
        <p>
          Pentru orice întrebări sau nelămuriri referitoare la această politică
          de confidențialitate, vă rugăm să ne contactați la
          contact@cautadentist.ro.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPol;
