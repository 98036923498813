import React, { useState } from "react";
import emailjs from "emailjs-com";
import "../styling/modal.css";
import Logo from "../assets/logo1.png";

const ModalForm = ({ toggleModal }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    clinic: "",
    address: "",
    shortDescription: "",
    specialties: "",
    city: "",
    contactLink: "",
    email: "",
    phone: "",
  });
  const [formMessage, setFormMessage] = useState("");

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate that contactLink starts with "https://"
    if (!formData.contactLink.startsWith("https://")) {
      setFormMessage("Linkul Facebook/Website trebuie să înceapă cu https://");
      return;
    }

    emailjs
      .send(
        "service_dlkrlpw", // Replace with your EmailJS service ID
        "template_zcgn0di", // Replace with your EmailJS template ID
        formData,
        "roGREg1cc2MfhW87b" // Replace with your EmailJS user ID
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormMessage(
            "Multumim pentru mesaj, va vom raspunde in cel mai scurt timp posibil"
          );
        },
        (err) => {
          console.log("FAILED...", err);
          setFormMessage(`A aparut o eroare: ${err.text}`);
        }
      );
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={toggleModal}>
          &times;
        </span>
        <div className="logo-container-modal">
          <img src={Logo} alt="CautaDentist.ro" className="logo-modal" />
        </div>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label className="input-container">
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required
                  placeholder="Nume"
                />
              </label>
              <label className="input-container">
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required
                  placeholder="Prenume"
                />
              </label>
            </div>
            <div className="form-group">
              <label className="input-container">
                <input
                  type="text"
                  name="clinic"
                  value={formData.clinic}
                  onChange={handleInputChange}
                  required
                  placeholder="Nume clinică"
                />
              </label>
              <label className="input-container">
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                  placeholder="Adresă"
                />
              </label>
            </div>
            <div className="form-group">
              <label className="input-container">
                <input
                  type="text"
                  name="shortDescription"
                  value={formData.shortDescription}
                  onChange={handleInputChange}
                  required
                  placeholder="Descriere"
                />
              </label>
              <label className="input-container">
                <input
                  type="text"
                  name="specialties"
                  value={formData.specialties}
                  onChange={handleInputChange}
                  required
                  placeholder="Specializari"
                />
              </label>
            </div>
            <div className="form-group">
              <label className="input-container">
                <input
                  type="text"
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  required
                  placeholder="Oraș"
                />
              </label>
              <label className="input-container">
                <input
                  type="text"
                  name="contactLink"
                  value={formData.contactLink}
                  onChange={handleInputChange}
                  required
                  placeholder="Link pagină de Facebook/Website"
                />
              </label>
            </div>
            <div className="form-group">
              <label className="input-container">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  placeholder="Adresă de email"
                />
              </label>
              <label className="input-container">
                <input
                  type="text"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  required
                  placeholder="Număr de telefon"
                />
              </label>
            </div>
            <button className="button-modal" type="submit">
              Trimite
            </button>
          </form>
          {formMessage && (
            <div
              className={`form-message ${
                formMessage.includes("eroare") ? "error" : "success"
              }`}>
              {formMessage}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalForm;
