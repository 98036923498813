import React, { useState, useRef, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import "./App.css";
import Acasa from "./pages/Acasa";
import Despre from "./pages/Despre";
import Contact from "./pages/Contact";
import TermsCond from "./data/TermsCond";
import PrivacyPol from "./data/PrivacyPol";
import { WhatsAppWidget } from "react-whatsapp-widget";
import "react-whatsapp-widget/dist/index.css";
import MediciNoReviews from "./pages/MediciNoReviews";
import ModalForm from "./components/ModalForm";

const MainContent = ({ onSearch, searchTerm, selectedCity }) => {
  const mediciRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/") {
      mediciRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [location]);

  return (
    <div>
      <div id="acasa">
        <Acasa onSearch={onSearch} />
      </div>
      <div id="despre">
        <Despre />
      </div>
      <div id="medici" ref={mediciRef}>
        <MediciNoReviews searchTerm={searchTerm} selectedCity={selectedCity} />
      </div>
      <div id="contact">
        <Contact />
      </div>
    </div>
  );
};

const App = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSearch = (term) => {
    setSearchTerm(term);
    // Scroll to Medici section
    document.getElementById("medici").scrollIntoView({ behavior: "smooth" });
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Router>
      <Navbar toggleModal={toggleModal} />
      <Routes>
        <Route
          path="/"
          element={
            <MainContent onSearch={handleSearch} searchTerm={searchTerm} />
          }
        />
        <Route path="/termeni" element={<TermsCond />} />
        <Route path="/privacy" element={<PrivacyPol />} />
        <Route
          path="/:city"
          element={
            <MainContent
              onSearch={handleSearch}
              searchTerm={searchTerm}
              selectedCity={undefined}
            />
          }
        />
      </Routes>
      <Footer />
      {isModalOpen && <ModalForm toggleModal={toggleModal} />}
      <WhatsAppWidget
        phoneNumber="+40744497740"
        message="Buna ziua! Cum te pot ajuta?"
        companyName="CautaDentist.ro"
        messageStyle={{ fontFamily: "Roboto", fontSize: "14px" }}
        disableText="Ascunde"
        enableText="Arată"
        placeholder="Scrie un mesaj"
        showPopupOnLoad={true}
        autoOpenTimeout={3000}
        messages={{
          replyPlaceholder: "Scrie un mesaj...",
          introMessage: "Bună! Cum te pot ajuta?",
          sendButton: "Trimite",
          disabledText: "Widget-ul WhatsApp este dezactivat momentan.",
          unavailableMessage: "Widget-ul WhatsApp nu este disponibil momentan.",
          retryMessage: "Încearcă din nou",
          promptMessage:
            "Completează câmpurile și apasă Enter pentru a trimite mesajul.",
          contactMessage: "Vă vom contacta cât mai curând posibil.",
          messageReceived: "Mesajul a fost primit! Vă vom răspunde în curând.",
          sendingMessage: "Se trimite mesajul...",
          messageSent: "Mesaj trimis!",
          messageFailed: "Trimitere mesaj eșuat. Încearcă din nou mai târziu.",
          unsupportedMessage:
            "Widget-ul WhatsApp nu este suportat pe acest dispozitiv sau browser.",
          numberSuggestion:
            "Poți să ne suni sau să ne trimiți un mesaj pe WhatsApp.",
        }}
      />
    </Router>
  );
};

export default App;
