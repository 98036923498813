import React from "react";
import PropTypes from "prop-types";
import "../styling/medicCard.css";
import Email from "../assets/email.png";
import Phone from "../assets/phone.png";
import Loc from "../assets/loc.png";
import Site from "../assets/site.png";
import Facebook from "../assets/facebook.png";

const MedicCard = ({
  nume,
  prenume,
  spec,
  clinica,
  oras,
  phone,
  mail,
  image,
  description,
  address,
  website = "",
  facebook = "",
  reviews = { rating: null, userRatingsTotal: null },
  placeId = "",

  distance = null,
}) => {
  // const [showPopup, setShowPopup] = useState(false);

  // const handleMouseEnter = () => {
  //   setShowPopup(true);
  // };

  // const handleMouseLeave = () => {
  //   setShowPopup(false);
  // };

  // const handleClick = () => {
  //   const reviewUrl = `https://www.google.com/maps/place/?q=place_id:${placeId}`;
  //   window.open(reviewUrl, "_blank");
  // };

  const getGoogleMapsLink = (address) => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
  };

  const handleAppointmentClick = () =>
    (window.location.href = `mailto:${mail}?subject=Programare ${nume} via CautaDentist.ro&bcc=contact@cautadentist.ro`);

  // const renderStars = (rating) => {
  //   const fullStars = Math.floor(rating);
  //   const halfStar = rating % 1 !== 0 ? 1 : 0;
  //   const emptyStars = 5 - fullStars - halfStar;

  //   return (
  //     <>
  //       {Array.from({ length: fullStars }, (_, index) => (
  //         <span key={`full-${index}`} className="full-star">
  //           ★
  //         </span>
  //       ))}
  //       {halfStar === 1 && (
  //         <span key="half" className="half-star">
  //           ★
  //         </span>
  //       )}
  //       {Array.from({ length: emptyStars }, (_, index) => (
  //         <span key={`empty-${index}`} className="empty-star">
  //           ★
  //         </span>
  //       ))}
  //     </>
  //   );
  // };

  return (
    <div className="medic-card">
      <img className="medic-image" src={image} alt={`${nume} ${prenume}`} />
      <div className="text-card">
        <h2 className="set-width1">{`${nume} ${prenume}`}</h2>
        <p className="set-width">
          <b>Clinica:</b> {` ${clinica}`}
        </p>
        <p className="set-width">
          <b>Oraș:</b> {`${oras}`}
        </p>
        <p className="descriere">{`${description}`}</p>
        {typeof distance === "number" && (
          <p className="set-width">
            <b>Distanță:</b> {`${distance} km`}
          </p>
        )}
      </div>
      <div className="buttons-card">
        <a className="holder-icon" href={`tel:${phone}`}>
          <img className="icon-card-button" src={Phone} alt={phone} />
        </a>
        <a className="holder-icon" href={`mailto:${mail}`}>
          <img className="icon-card-button" src={Email} alt={mail} />
        </a>
        {website ? (
          <a
            className="holder-icon"
            href={website}
            target="_blank"
            rel="noopener noreferrer">
            <img className="icon-card-button" src={Site} alt={website} />
          </a>
        ) : (
          facebook && (
            <a
              className="holder-icon"
              href={facebook}
              target="_blank"
              rel="noopener noreferrer">
              <img className="icon-card-button" src={Facebook} alt={facebook} />
            </a>
          )
        )}
        <a
          className="holder-icon"
          href={getGoogleMapsLink(address)}
          target="_blank"
          rel="noopener noreferrer">
          <img className="icon-card-button" src={Loc} alt={address} />
        </a>
      </div>
      <button
        className="medic-button appointment-button"
        onClick={handleAppointmentClick}>
        Programează-te
      </button>
    </div>
  );
};

MedicCard.propTypes = {
  nume: PropTypes.string.isRequired,
  prenume: PropTypes.string.isRequired,
  spec: PropTypes.string.isRequired,
  clinica: PropTypes.string.isRequired,
  oras: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  mail: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  website: PropTypes.string,
  facebook: PropTypes.string,
  reviews: PropTypes.shape({
    rating: PropTypes.number,
    userRatingsTotal: PropTypes.number,
  }),
  placeId: PropTypes.string,
  distance: PropTypes.number,
};
export default MedicCard;
