import React from "react";
import "../styling/acasa.css";
import { Link } from "react-scroll";

const Acasa = () => {
  return (
    <>
      <div className="home-container">
        <div className="header">
          <h1 className="header-title">Încredere, Conveniență, Expertiză.</h1>
          <h2 className="header-subtitle">
            Descoperă stomatologi de încredere, programează-te online rapid și
            ușor, și beneficiază de expertiză profesională pentru zâmbetul tău
            perfect.
          </h2>
          <div className="search-holder">
            <Link
              to="medici"
              className="cautaMedic"
              smooth={true}
              duration={500}>
              Caută Medic
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Acasa;
