import React, { useState, useEffect, useCallback } from "react";
import "../styling/medici.css";
import Cities from "../data/cities.json";
import MedicCard from "../components/MedicCard";
import { db } from "../data/firebase.js";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import Image from "../assets/dr.png";
import { useParams, useNavigate } from "react-router-dom";
import haversine from "haversine-distance";
import Modal from "../components/ModalForm.js"; // Importați componenta Modal

const MediciNoReviews = ({ searchTerm }) => {
  const [mediciList, setMediciList] = useState([]);
  const [order, setOrder] = useState("Alfabetic Crescator");
  const [filteredMedici, setFilteredMedici] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const [localSearchTerm, setLocalSearchTerm] = useState(searchTerm || "");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [userLocation, setUserLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State pentru modal
  const { city } = useParams();
  const navigate = useNavigate();

  const itemsPerPage = 8;

  const fetchAllMedici = useCallback(async () => {
    const mediciCollection = collection(db, "medici");
    const mediciSnapshot = await getDocs(
      query(mediciCollection, orderBy("nume"))
    );
    const mediciData = mediciSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Fetched medici data: ", mediciData);
    setMediciList(mediciData);
  }, []);

  useEffect(() => {
    fetchAllMedici();
  }, [fetchAllMedici]);

  const sortMedici = useCallback((medici, order) => {
    return medici.sort((a, b) => {
      if (order === "Alfabetic Crescator") {
        return a.nume.localeCompare(b.nume);
      } else if (order === "Alfabetic Descrescator") {
        return b.nume.localeCompare(a.nume);
      }
      return 0;
    });
  }, []);

  const calculateDistances = useCallback((medici, userLocation) => {
    const mediciWithCoords = [];
    const mediciWithoutCoords = [];

    medici.forEach((medic) => {
      if (medic.lat && medic.lng) {
        const medicLocation = { latitude: medic.lat, longitude: medic.lng };
        const distance = haversine(userLocation, medicLocation) / 1000; // convert to kilometers
        mediciWithCoords.push({
          ...medic,
          distance: Number(distance.toFixed(2)),
        });
      } else {
        mediciWithoutCoords.push(medic);
      }
    });

    mediciWithCoords.sort((a, b) => a.distance - b.distance);

    return [...mediciWithCoords, ...mediciWithoutCoords];
  }, []);

  useEffect(() => {
    if (city) {
      setSelectedCity(city);
    }
  }, [city]);

  useEffect(() => {
    console.log("User location: ", userLocation);
    let results = mediciList.filter((medic) => {
      const searchTermLower = localSearchTerm.toLowerCase();
      return (
        (medic.nume && medic.nume.toLowerCase().includes(searchTermLower)) ||
        (medic.prenume &&
          medic.prenume.toLowerCase().includes(searchTermLower)) ||
        (medic.clinica &&
          medic.clinica.toLowerCase().includes(searchTermLower)) ||
        (medic.oras && medic.oras.toLowerCase().includes(searchTermLower))
      );
    });

    if (selectedCity) {
      results = results.filter(
        (medic) =>
          medic.oras && medic.oras.toLowerCase() === selectedCity.toLowerCase()
      );
    }

    if (userLocation) {
      results = calculateDistances(results, userLocation);
    } else {
      results = sortMedici(results, order);
    }

    setTotalPages(Math.ceil(results.length / itemsPerPage));
    setFilteredMedici(results);
  }, [
    localSearchTerm,
    selectedCity,
    order,
    mediciList,
    sortMedici,
    calculateDistances,
    userLocation,
  ]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const sortedCities = Cities.cities.sort((a, b) =>
    a.city.localeCompare(b.city)
  );

  const handleCityChange = (e) => {
    const newCity = e.target.value;
    setSelectedCity(newCity);
    navigate(`/${newCity}`);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    }
  }, []);

  const paginatedMedici = filteredMedici.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="medici-container">
      <div className="header-medici">
        <div className="title-medici">Medici</div>
        <div className="subtitle-medici">
          Profesioniști de încredere pentru zâmbetul tău!
        </div>
        <div className="filtering-section">
          <div className="top-filter">
            <input
              type="text"
              className="input-search-medici"
              placeholder="Căutare după nume, prenume, clinică sau oraș"
              value={localSearchTerm}
              onChange={(e) => setLocalSearchTerm(e.target.value)}
            />
          </div>
          <div className="down-filter">
            <div className="oras">
              <label htmlFor="oras">Oras</label>
              <select
                name="Oras"
                id="oras"
                value={selectedCity}
                onChange={handleCityChange}>
                <option value="">Toate</option>
                {sortedCities.map((cityObj) => (
                  <option key={cityObj.city} value={cityObj.city}>
                    {cityObj.city}
                  </option>
                ))}
              </select>
            </div>
            <div className="order">
              <label htmlFor="ordoneaza">Ordonare</label>
              <select
                name="Ordoneaza"
                id="ordoneaza"
                value={order}
                onChange={(e) => setOrder(e.target.value)}>
                <option value="Alfabetic Crescator">Alfabetic Crescator</option>
                <option value="Alfabetic Descrescator">
                  Alfabetic Descrescator
                </option>
              </select>
            </div>
          </div>
        </div>
        <div className="medici-list">
          {paginatedMedici.length > 0 ? (
            paginatedMedici.map((medic, index) => {
              return (
                <div className="medici-card1" key={index}>
                  <MedicCard
                    nume={medic.nume || "N/A"}
                    prenume={medic.prenume || "N/A"}
                    spec={medic.spec || "N/A"}
                    clinica={medic.clinica || "N/A"}
                    oras={medic.oras || "N/A"}
                    phone={medic.phone || "N/A"}
                    mail={medic.mail || "N/A"}
                    image={medic.image || Image}
                    description={medic.description || "N/A"}
                    address={medic.address || "N/A"}
                    website={medic.website}
                    facebook={medic.facebook}
                    distance={medic.distance}
                  />
                </div>
              );
            })
          ) : (
            <div>
              <p className="nu-gasit">
                Niciun medic găsit în: {selectedCity}. Ești medic?
                <button onClick={toggleModal} className="btn-medici-inscrie">
                  Înscrie-te
                </button>
              </p>
            </div>
          )}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <div className="button-container" key={page}>
              <button
                className={`button-3d ${currentPage === page ? "active" : ""}`}
                onClick={() => handlePageChange(page)}
                disabled={currentPage === page}>
                <div className="button-top">{page}</div>
                <div className="button-bottom"></div>
                <div className="button-base"></div>
              </button>
            </div>
          ))}
        </div>
      </div>
      {isModalOpen && <Modal toggleModal={toggleModal} />}
    </div>
  );
};

export default MediciNoReviews;
