import React from 'react'
import "../styling/terms.css"

const TermsCond = () => {
  return (
    <div className="termeni-container">
      <div className="termeni-content">
        <h1>Termeni și Condiții</h1>
        <p>Ultima actualizare: 18 iunie 2024</p>

        <h2>1. Introducere</h2>
        <p>
          Bine ați venit pe platforma noastră de conectare a pacienților cu
          medicii stomatologi, powered by upvisionMedia din București.
          Utilizând această platformă, sunteți de acord cu acești termeni și
          condiții.
        </p>

        <h2>2. Utilizarea platformei</h2>
        <p>
          Platforma noastră este destinată să ajute pacienții să găsească medici
          stomatologi și să programeze consultații. Nu suntem responsabili
          pentru serviciile medicale furnizate de medicii listati pe platforma
          noastră.
        </p>

        <h2>3. Conturi de utilizator</h2>
        <p>
          Pentru a utiliza anumite funcționalități ale platformei, trebuie să vă
          creați un cont. Sunteți responsabil pentru menținerea
          confidențialității informațiilor de autentificare și pentru toate
          activitățile care se desfășoară în contul dumneavoastră.
        </p>

        <h2>4. Politica de confidențialitate</h2>
        <p>
          Informațiile personale colectate prin intermediul platformei noastre
          vor fi tratate conform politicii noastre de confidențialitate. Vă
          rugăm să citiți politica noastră de confidențialitate pentru mai multe
          detalii.
        </p>

        <h2>5. Limitarea răspunderii</h2>
        <p>
          UpvisionMedia Agency nu va fi responsabilă pentru niciun fel de daune
          directe, indirecte, incidentale sau speciale care rezultă din
          utilizarea sau incapacitatea de a utiliza platforma noastră.
        </p>

        <h2>6. Modificări ale termenilor</h2>
        <p>
          Ne rezervăm dreptul de a modifica acești termeni și condiții în orice
          moment. Orice modificări vor fi publicate pe această pagină, iar
          utilizarea continuă a platformei noastre va constitui acceptarea
          acestor modificări.
        </p>

        <h2>7. Contact</h2>
        <p>
          Pentru orice întrebări sau nelămuriri referitoare la acești termeni și
          condiții, vă rugăm să ne contactați la contact@cautadentist.ro.
        </p>
      </div>
    </div>
  );
}

export default TermsCond