import React, { useState } from "react";
import "../styling/despre.css";
import StomaTimisoara from "../assets/stoma.webp";
import Upvm from "../assets/upvm.webp";

const Despre = () => {
  const [activeIndex, setActiveIndex] = useState(null); // State to keep track of the active accordion item

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index); // Toggle accordion item
  };

  return (
    <div className="about-container">
      <h3 className="title-about">
        Platforma ta de încredere pentru găsirea rapidă a medicilor stomatologi
        profesioniști.
      </h3>
      <div className="accordion-container">
        <div className="accordion-item" onClick={() => handleToggle(1)}>
          <h4 className="accordion-title">01 - Utilizează bara de căutare</h4>
          {activeIndex === 1 && (
            <p className="accordion-content">
              Utilizează bara de căutare pentru a găsi stomatologi după locație,
              specializare sau nume.
            </p>
          )}
        </div>
        <div className="accordion-item" onClick={() => handleToggle(2)}>
          <h4 className="accordion-title">02 - Citește detalii despre medic</h4>
          {activeIndex === 2 && (
            <p className="accordion-content">
              Citește detalii despre medic, inclusiv specializări, experiență,
              recenzii ale pacienților și informații de contact.
            </p>
          )}
        </div>
        <div className="accordion-item" onClick={() => handleToggle(3)}>
          <h4 className="accordion-title">03 - Verifică disponibilitatea</h4>
          {activeIndex === 3 && (
            <p className="accordion-content">
              Verifică disponibilitatea stomatologului și selectează data și ora
              care îți convine. Totul este simplu și rapid, direct din
              platformă.
            </p>
          )}
        </div>
        <div className="accordion-item" onClick={() => handleToggle(4)}>
          <h4 className="accordion-title">04 - Completează detaliile finale</h4>
          {activeIndex === 4 && (
            <p className="accordion-content">
              Completează detaliile finale și confirmă programarea. Primești o
              notificare de confirmare și ești gata pentru consultația ta.
            </p>
          )}
        </div>
      </div>
      <div className="partners-container">
        <h4 className="partners-title">Partenerii noștri</h4>
        <div className="partners-cards">
          <div className="partner">
            <a
              className="partner-link"
              href="https://www.dentisttimisoara.ro/"
              target="black">
              <img
                src={StomaTimisoara}
                alt="Partner 1 Logo"
                className="partner-logo"
              />
              <span className="partner-text">
                <span style={{ fontWeight: "bold" }}>
                  Dentistul Vlădescu Răzvan din Timișoara
                </span>
                oferă servicii stomatologice de înaltă calitate pentru întreaga
                familie. Specializat în igienă orală, profilaxie, tratamente
                endodontice și estetică dentară, se asigură că fiecare pacient
                primește îngrijire personalizată într-un mediu confortabil și
                modern. Cu ajutorul echipamentelor de ultimă generație și a unei
                echipe dedicate de profesioniști, tratamentele sunt eficiente și
                oferă rezultate de durată.
              </span>
            </a>
          </div>
          <div className="partner">
            <a
              className="partner-link"
              href="https://www.upvisionmedia.com/"
              target="black">
              <img src={Upvm} alt="Partner 2 Logo" className="partner-logo" />

              <span className="partner-text">
                <span style={{ fontWeight: "bold" }}>upvisionMedia </span>este o
                companie specializată exclusiv în crearea de site-uri web
                personalizate, oferind soluții unice și inovatoare pentru
                fiecare client în parte. Fie că ești un start-up în căutarea
                unei prezențe online impresionante sau o afacere bine stabilită
                care dorește să își îmbunătățească website-ul, upvisionMedia
                este partenerul ideal pentru tine.
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Despre;
