import React from "react";
import "../styling/contact.css";

const Contact = () => {
  return (
    <div className="contact-container">
      <div className="top-text">Vrei să ne contactezi?</div>
      <div>
        <a className="lower-text" href="mailto:contact@cautadentist.ro">
          contact@cautadentist.ro
        </a>
      </div>
    </div>
  );
};

export default Contact;
