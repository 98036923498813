import React from "react";
import Logo from "../assets/logof.png";
import { Link } from "react-scroll";
import "../styling/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
        <Link to="acasa" smooth={true} duration={500} className="footer-link">
          <div className="logo-container">
            <img src={Logo} alt="Logo" className="footer-logo" />
          </div>
        </Link>
        <div className="nav-links2-footer">
          <Link to="acasa" smooth={true} duration={500} className="footer-link">
            Acasă
          </Link>
          <Link
            to="despre"
            smooth={true}
            duration={500}
            className="footer-link">
            Despre
          </Link>
          <Link
            to="medici"
            smooth={true}
            duration={500}
            className="footer-link">
            Medici
          </Link>
          <Link
            to="contact"
            smooth={true}
            duration={500}
            className="footer-link">
            Contact
          </Link>
        </div>
      </div>
      <div className="footer-bottom">
        <Link
          to="termeni"
          smooth={true}
          duration={500}
          className="footer-bottom-link">
          Termeni și Condiții
        </Link>
        <span className="footer-bottom-text">
          Powered by{" "}
          <a
            style={{ color: "#fc4308", textDecoration: "none" }}
            href="http://upvisionmedia.com"
            target="_blank"
            rel="noopener noreferrer">
            upvisionMedia
          </a>
        </span>
        <Link
          to="privacy"
          smooth={true}
          duration={500}
          className="footer-bottom-link">
          Privacy Policy
        </Link>
      </div>
    </footer>
  );
};

export default Footer;
